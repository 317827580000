<script lang="ts" setup> 

    const img = useImage()

    const props = defineProps({ 
        blok: Object 
    })

    const imageCheck = (img:string) => {
        return img.split('.').pop() || ''
    }

    const width = (value:string) => {
        return value.split('/')[5].split('x')[0]
    }

    const height = (value:string) => {
        return value.split('/')[5].split('x')[1]
    }

    const richtext = (value:any) => renderRichText(value) 

</script>

<template>
   <div v-editable="blok" class="grid grid-col-1" :class="[blok?.theme || 'dark']">
        <div class="flex flex-col gap-6 lg:gap-8">
      
            <div v-if="blok?.heading || blok?.subheading || blok?.richtext && richtext(blok?.richtext)" class="flex flex-col gap-4 justify-center items-center text-center max-w-[900px] mx-auto">
                <div v-if="blok?.heading || blok?.subheading" class="flex flex-col gap-2 justify-center items-center text-center">
                    <component
                        v-if="blok && blok?.heading" 
                        :is="`headings-${blok?.heading_tag || 'h3'}`" 
                        :value="blok?.heading"
                        :classes="blok?.heading_style || 'h2'" 
                    />
                    <component
                        v-if="blok && blok?.subheading" 
                        :is="`headings-${blok?.subheading_tag || 'h4'}`" 
                        :value="blok?.subheading"
                        :classes="blok?.subheading_style || 'h3'"
                    />
                </div>
                <div v-if="blok?.richtext && richtext(blok?.richtext)" v-html="richtext(blok?.richtext)" class="flex flex-col gap-2 | font-hass-text text-p_mobile md:text-p_tablet lg:text-p_desktop font-normal text-white-50/75 dark:text-nevada-500 | [&>ul]:mx-auto [&>ul]:flex [&>ul]:flex-col [&>ul]:gap-1 [&>ul]:justify-center [&>ul]:items-start [&>ul>li]:relative [&>ul>li]:pl-2 [&>ul>li]:before:absolute [&>ul>li]:before:content-[''] [&>ul>li]:before:w-1 [&>ul>li]:before:h-1 [&>ul>li]:before:bg-tropical-rain-forest-700 [&>ul>li]:before:top-1/2 [&>ul>li]:before:left-0 [&>ul>li]:before:-translate-y-1/2 [&>ul>li]:before:rounded-full   "/>
            </div>

            <div 
                v-if="blok?.columns && blok?.columns.length > 0"
                class="grid gap-4 lg:gap-8"
                :class="[blok?.grid_columns_mobile, blok?.grid_columns_tablet, blok?.grid_columns_desktop]"
            >
                <div v-for="column in blok.columns" :key="column._uid" v-editable="column" class="grid grid-col-1">
                    <div class="flex flex-col gap-4">
                        <template v-if="column?.image && column?.image?.filename">
                            <template v-if="imageCheck(column.image.filename) === 'svg'">
                                <NuxtImg
                                    v-if="column?.image && column?.image?.filename"
                                    provider="storyblok"
                                    width="160"
                                    height="160"
                                    :src="column?.image?.filename"
                                    :alt="column?.image?.alt || column?.image?.title || '#'" 
                                    :fetchpriority="'auto'"
                                    :decoding="'async'"
                                    :loading="'lazy'"
                                    class="mx-auto"
                                    :class="[column?.image_size || 'w-10 h-10']"
                                />
                            </template>
                            <template v-else>
                                <NuxtImg
                                    v-if="column?.image && column?.image?.filename"
                                    provider="storyblok"
                                    :width="width(column?.image?.filename)"
                                    :height="height(column?.image?.filename)" 
                                    :sizes="`xs:100vw sm:${width(column?.image?.filename)} md:${width(column?.image?.filename)} narrow:${width(column?.image?.filename)} lg:${width(column?.image?.filename)} xl:${width(column?.image?.filename)} 1xl:${width(column?.image?.filename)} normal:${width(column?.image?.filename)} 2xl:${width(column?.image?.filename)} 3xl:${width(column?.image?.filename)} wide:${width(column?.image?.filename)}`"
                                    quality="75"
                                    fit="in"
                                    :placeholder="[width(column?.image?.filename), height(column?.image?.filename), 10, 5]"
                                    :src="column?.image?.filename"
                                    :alt="column?.image?.alt || column?.image?.title || '#'" 
                                    :fetchpriority="'auto'"
                                    :decoding="'async'"
                                    :loading="'lazy'"
                                    class="mx-auto"
                                    :class="[column?.image_size || 'w-10 h-10']"
                                />
                            </template>
                        </template>
                        <div v-if="column?.heading || column?.subheading" class="flex flex-col gap-2 justify-center items-center text-center">
                            <h3 v-if="column?.heading" v-html="column.heading" class="font-hass-display text-h5_mobile md:text-h5_tablet lg:text-h5_desktop font-medium text-elephant-950 text-center"/>
                            <h4 v-if="column?.subheading" v-html="column.subheading" class="font-hass-display text-h7_mobile md:text-h7_tablet lg:text-h7_desktop font-roman text-nevada-500 text-center"/>
                        </div>
                        <div v-if="column?.richtext && richtext(column?.richtext)" v-html="richtext(column?.richtext)" class="flex flex-col gap-2 | font-hass-text text-p_mobile md:text-p_tablet lg:text-p_desktop font-normal text-nevada-500 [&>*>a]:text-lima-600 [&>*>a]:underline text-center"/>
                        <div v-if="column?.buttons && column?.buttons.length > 0" class="flex flex-wrap gap-2 justify-center items-center text-center">
                            <StoryblokComponent v-for="button in column.buttons" :key="button._uid" :blok="button" />
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="blok?.buttons && blok?.buttons.length > 0" class="flex flex-wrap gap-2 justify-center items-center text-center">
                <StoryblokComponent v-for="button in blok.buttons" :key="button._uid" :blok="button" />
            </div>

        </div>
   </div>
</template>